import { render, staticRenderFns } from "./Diskusi.vue?vue&type=template&id=b431c604&scoped=true"
import script from "./Diskusi.vue?vue&type=script&lang=js"
export * from "./Diskusi.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b431c604",
  null
  
)

export default component.exports